import { useState, useEffect } from 'react'

export function useMobileTabletDetect() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(() => {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    const isTablet = /iPad|Android|Tablet/i.test(navigator.userAgent)

    const screenWidth = window.screen.width
    const screenHeight = window.screen.height

    const isMobileScreen = screenWidth < 768 || screenHeight < 768 // Adjust threshold as needed
    const isTabletScreen =
      screenWidth >= 768 && screenHeight >= 768 && screenWidth < 1024 // Adjust threshold as needed

    return isMobile || isTablet || isMobileScreen || isTabletScreen
  })

  useEffect(() => {
    const handleResize = () => {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      const isTablet = /iPad|Android|Tablet/i.test(navigator.userAgent)

      const screenWidth = window.screen.width
      const screenHeight = window.screen.height

      const isMobileScreen = screenWidth < 768 || screenHeight < 768 // Adjust threshold as needed
      const isTabletScreen =
        screenWidth >= 768 && screenHeight >= 768 && screenWidth < 1024 // Adjust threshold as needed

      const isMobileOrTabletDevice =
        isMobile || isTablet || isMobileScreen || isTabletScreen
      setIsMobileOrTablet(isMobileOrTabletDevice)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobileOrTablet
}
